<template>
  <div>
     <div class="setting-box">
      <el-form
        v-if="site"
        v-model="site"
        ref="form"
        label-width="120px"
        @submit.native.stop.prevent="formSubmit('site')"
      >
        <h3>网站设置</h3>
        <el-form-item
          prop="appid"
          label="网站地址"
        >
          <el-input
            type="text"
            v-model.trim="site.body.webUrl"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="appid"
          label="小程序名称"
        >
          <el-input
            type="text"
            v-model.trim="site.body.miniName"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            native-type="submit"
            :loading="Loading"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'WebSite',
  data () {
    return {
      Loading: false,
      site: null
    }
  },
  async mounted () {
    const { data } = await flatry(SettingService.edit())
    if (data.data.site) {
      this.site = data.data.site
    } else {
      this.site = {
        type: 6,
        body: {
          webUrl: '',
          miniName: ''
        }
      }
    }
  },
  methods: {
    formSubmit () {
      this.editCreate(this.site)
    },
    async editCreate (param, success, callback) {
      const { data } = await flatry(SettingService.edit(param))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/web-site' })
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 120px;
        font-size: 1.06em;
    }
}
</style>
